@import './utility/index.scss';

.clientInfoContainer > * {
	margin: 16px;
}
//top Section
.TopSection {
	&_clientInfo {
		color: $color-grey-light !important;
		font-family: $font-quicksand-sanseriff;
		font-size: 2.8rem;
	}

	&_breadcrums {
		text-transform: uppercase;
		display: flex;
		justify-content: center;
		align-items: center;

		p {
			margin: 0;
		}
	}

	&_buttonsConatiner > * {
		@media only screen and (max-width: 768px) {
			margin-top: 1.6rem;
		}
	}

	&_buttonsConatiner > * + * {
		margin-left: 10px;
	}

	&_specialButtonLeft {
		background: white;
		border: 1px solid $color-grey-orange;
		box-sizing: border-box;
		// border-radius: 48px;
		color: $color-grey-orange;

		&:hover {
			background: white;
			border: 1px solid $color-grey-orange;
			color: $color-grey-orange;
		}
	}

	&_specialButtonRight {
		background: white;
		border: 1px solid $color-pink-red;
		box-sizing: border-box;
		// border-radius: 48px;
		color: $color-pink-red;

		&:hover {
			background: white;
			border: 1px solid $color-pink-red;
			color: $color-pink-red;
		}
	}
}

//stepper
.Stepper {
	&_card {
		overflow: auto;
		padding: 0 !important;
	}

	&_steps {
		font-weight: 400;
		font-style: normal;
	}
}

.dividerStyle {
	margin: 16px 0;
}

.CardsContainer {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	&_ClientMainInfoCard {
		width: 49%;

		@media only screen and (max-width: 768px) {
			width: 100%;
		}

		&_TopSection {
			padding-bottom: 0;
			display: flex;
			justify-content: center;
			align-items: center;

			@media (max-width: $breakpoint-tablet) {
				padding-bottom: 1.6rem;
			}

			@media (max-width: $breakpoint-mobile) {
				padding-bottom: 1.6rem;
			}

			&_specialPtag {
				font-size: 1rem;
				color: color-grey;

				&_span {
					font-weight: 700;
				}
			}
		}

		&_buttonsConatiner {
			&_EditButton {
				background: whitesmoke;
				border: 1px solid $color-grey-hue;
				box-sizing: border-box;
				// border-radius: 24px;
				margin: 1.6rem;

				@media (max-width: $breakpoint-mobile) {
					margin-left: 0;
				}

				&:hover {
					color: $color-grey-hue;
					background: whitesmoke;
					border: 1px solid $color-grey-hue;
				}
			}

			&_ActiveButton {
				background: $color-vanilla;
				border: 1px solid $color-ligh-green;
				border-radius: 4px;
				color: $color-ligh-green;

				&:hover {
					color: $color-ligh-green;
					background: $color-vanilla;
					border: 1px solid $color-ligh-green;
				}
			}
		}

		&_buttonsConatiner > * + * {
			margin-left: 10px;

			@media (max-width: $breakpoint-mobile) {
				margin-left: 0;
			}
		}

		&_infoContainer {
			width: 100%;
			// margin: 0 16px 0 0;
			line-height: 170%;

			&_half {
				width: 50%;
				color: $color-grey;
				font-weight: 400;

				&_title {
					color: $color-grey;
					font-weight: 700;
				}
			}
		}
	}

	&_ClientAdditionalInfoCard {
		width: 49%;

		@media only screen and (max-width: 768px) {
			width: 100%;
			margin-top: 1rem;
		}

		&_infoContainer {
			width: 100%;
			// margin: 0 16px 0 0;
			line-height: 170%;
			overflow: auto;

			&_half {
				width: 50%;
				color: $color-grey;
				font-weight: 400;

				&_title {
					color: $color-grey;
					font-weight: 700;
				}
			}
		}
	}
}

.registerButton {
	background: $color-light-green-white;
	border: 1px solid $color-light-purple;
	box-sizing: border-box;
	border-radius: 4px;
	color: $color-light-purple;
	width: 53px;
	// height: 22px;
	padding: 4px 8px;
	text-align: center;
}

.BottomSection {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;

	&_half {
		width: 49%;

		@media only screen and (max-width: 768px) {
			width: 100%;
		}
	}

	&_responsive {
		@media only screen and (max-width: 768px) {
			margin-top: 16px;
		}
	}

	&_issue {
		margin-top: 16px;
	}
}

.Clientmodal {
	&_spanContainingRow {
		color: $color-grey;
	}

	&_inputSpecial {
		width: 230px;

		@media (max-width: $breakpoint-mobile) {
			width: 100%;
		}
	}
}

.cardsList > * + * {
	margin: 0 8px;
}
