@import './utility/index.scss';

.buttonStyle {
	background: whitesmoke;
	border: 1px solid $color-teal-green;
	box-sizing: border-box;
	color: $color-teal-green;

	&:hover {
		color: $color-teal-green;
		background: whitesmoke;
		border: 1px solid $color-teal-green;
	}
}

.infoContainer {
	width: 100%;
	box-sizing: border-box;
	border: 1px dashed $color-grey-light;
	border-radius: 4px;
	padding: 16px;
	// display: block;

	&_table {
		width: 100%;
		margin-top: 16px;
		overflow: auto;
	}
}

.Clientmodal {
	&_spanContainingRow {
		color: $color-grey;

		&_new {
			line-height: 170%;
			color: $color-grey;
		}
	}
}
