@import './utility/index.scss';

.clientCard {
	width: auto;
	height: auto;
	max-height: -webkit-fill-available;
	overflow-y: auto;
}

.responsive {
	margin: 24px;
	box-shadow: 0 5px 25px rgba(14, 14, 14, 0.0625);
	border-radius: 4px;
	overflow-x: auto;

	@media only screen and (max-width: 768px) {
		height: 750px;
		overflow: auto;
	}
}

.tableInputs {
	width: 384px;

	@media only screen and (max-width: 1024px) {
		width: 100%;
		margin-bottom: 10px;
	}

	@media only screen and (max-width: 768px) {
		width: 100%;
		margin-bottom: 10px;
	}
}

//client info repayments
.inputContainer > * + * {
	margin-left: 10px;

	@media only screen and (max-width: 1024px) {
		margin-left: 0;
		margin-top: 10px;
	}

	@media only screen and (max-width: 768px) {
		margin-left: 0;
		margin-top: 10px;
	}
}

.repamentsButtonContainer {
	margin: 1rem 0 0 0;
}

.repamentsButtonContainer > * + * {
	margin-left: 10px;
}

.Clientmodal {
	&_spanContainingRow {
		color: $color-grey;
	}
}

.sourceButton {
	// background: #e6f7ff;
	border: 1px solid;
	box-sizing: border-box;
	border-radius: 4px;
	// color: #5193ff;
	width: 60px;
	// height: 30px;
	padding: 4px 8px;
	text-align: center;
	margin: 0 0 8px 0;
}

.statusButton {
	color: $color-dark-yellow;
	background: $color-light-green-white;
	border: 1px solid $color-dark-yellow;
	box-sizing: border-box;
	border-radius: 4px;
	// width: 60px;
	// height: 22px;
	padding: 4px 8px;
	text-align: center;
	margin: 8px 0;
}

.ActiveButton {
	background: $color-vanilla;
	border: 1px solid $color-ligh-green;
	border-radius: 4px;
	color: $color-ligh-green;
	text-align: center;

	&:hover {
		color: $color-ligh-green;
		background: $color-vanilla;
		border: 1px solid $color-ligh-green;
	}
}

.transactionDetailsformContent {
	color: $color-grey;
	line-height: 170%;
}

.infoContainer_table {
	overflow: auto;
}

.cardsList > * + * {
	margin: 0 8px;
}

.collapse {
	width: 100%;
	background: none;
	border: none;
}

.collapseHeader {
	background: none;
	border: none;
	font-size: 1.6rem;
	line-height: 2.4rem;
	vertical-align: 0;
	color: $color-accordian-black;
	font-family: $font-oxygen-sanseriff;
	opacity: 0.66;
	padding: 0.8rem 0;
}

.customParagraph {
	font-family: $font-oxygen-sanseriff;
	font-weight: 400;
	line-height: 2.4rem;
	color: $color-grey;
	opacity: 0.66;
	font-size: 1.4rem !important;
	margin: 0;
}
