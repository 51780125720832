@import './utility/index.scss';

.container {
	box-sizing: border-box;
	border: 1px dashed $color-grey-light;
	border-radius: 4px;
	padding: 16px;
}

.container p {
	margin-bottom: 0;
}

.container > * + * {
	margin-top: 8px;
}

.imageHolders > * + * {
	margin-left: 16px;
}

.btnHolder {
	margin: 24px 0;
}

//modal css
.modal {
	&_span {
		color: $color-shade-of-black;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 22px;
	}

	&_LabelSpan {
		font-style: normal;
		font-weight: bold;
		line-height: 15px;
		color: $color-grey;
	}

	&_LabelSpanText {
		font-style: normal;
		line-height: 15px;
		color: $color-grey;
	}
}
