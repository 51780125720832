@import './utility/index.scss';

.dashLayout {
	min-height: 100vh;

	@media only screen and (max-width: 1024px) {
		height: auto;
	}

	&_sidenaveContainer {
		background-color: $color-white;
		width: 26.5rem !important;

		@media only screen and (max-width: 1024px) {
			width: 20.5rem !important;
		}

		@media only screen and (max-width: 768px) {
			display: none;
		}
	}

	&_headback {
		background-color: $color-white;
		padding: 0 16px !important;
	}
}
