@import './utility/index.scss';

.advance {
	&_title {
		color: $color-grey;
		font-style: normal;
		font-weight: bold;
		font-size: 14px;
	}

	&_text {
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		color: $color-grey;
	}

	&_infoCard {
		// padding: 0 16px;
		line-height: 170%;
	}
}

.infoContainer {
	width: 100%;
	box-sizing: border-box;
	border: 1px dashed $color-grey-light;
	border-radius: 4px;
	padding: 16px;

	&_half {
		width: 50%;
		color: $color-grey;
		font-weight: 400;

		&_title {
			color: $color-grey;
			font-weight: 700;
		}
	}
}

.ActiveButton {
	background: $color-vanilla;
	border: 1px solid $color-ligh-green;
	border-radius: 4px;
	color: $color-ligh-green;

	&:hover {
		color: $color-ligh-green;
		background: $color-vanilla;
		border: 1px solid $color-ligh-green;
	}
}

.titel {
	font-style: normal;
	font-weight: bold;
	font-size: 10px;
	color: $color-grey;
}

.text {
	font-style: normal;
	font-size: 12px;
	color: $color-grey;
}

.NewTitle {
	font-weight: bold;
	font-size: 14px;
	color: $color-grey;
	// text-transform: uppercase;
}

.NewText {
	font-size: 14px;
	color: $color-grey;
}

.registerButton {
	background: $color-light-green-white;
	border: 1px solid $color-light-purple;
	box-sizing: border-box;
	border-radius: 4px;
	color: $color-light-purple;
	width: 53px;
	// height: 22px;
	padding: 4px 8px;
}

.buttonsConatiner > * + * {
	margin-left: 10px;

	@media (max-width: $breakpoint-mobile) {
		flex-direction: column;
		margin-left: 0;
		margin-top: 10px;
		margin-right: 10px;
	}
}

.IssuebuttonsConatiner > * {
	margin-right: 0;

	@media (max-width: $breakpoint-mobile) {
		margin-right: 10px;
	}
}

.IssuebuttonsConatiner > * + * {
	margin-left: 10px;

	@media (max-width: $breakpoint-mobile) {
		flex-direction: column;
		margin-left: 0;
		margin-right: 10px;
	}
}

.Clientmodal {
	&_spanContainingRow {
		color: $color-grey;

		&_new {
			line-height: 170%;
			color: $color-grey;
		}
	}
}

.sourceButton {
	background: $color-light-green-white;
	border: 1px solid $color-light-purple;
	box-sizing: border-box;
	border-radius: 4px;
	color: $color-light-purple;
	width: 60px;
	// height: 22px;
	padding: 4px 8px;
	text-align: center;
	margin: 0 0 8px 0;
}

.gutterBox {
	margin: 0.1rem;
}
